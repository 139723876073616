import { RiSparklingFill } from '@remixicon/react'
import { Button, Card, Checkbox } from 'antd'
import { t } from 'i18next'
import React, { useState } from 'react'

import { IGeneratedCheckboxGroup } from '@/types/chatbot'

interface GeneratedCheckboxGroupProps extends IGeneratedCheckboxGroup {
  getAnswer: (
    domain: boolean,
    question: string,
    agent?: string
  ) => Promise<void>
}

const GeneratedCheckboxGroup: React.FC<GeneratedCheckboxGroupProps> = ({
  items,
  getAnswer,
}) => {
  const [selected, setSelected] = useState<string[]>([])

  return (
    <Card>
      <Checkbox.Group
        className='flex flex-col gap-1'
        onChange={(list: string[]) => setSelected(list)}
        value={selected}
        options={items.map((item) => item.text)}
      />
      <div className='mt-4 flex justify-end'>
        <Button
          className='flex items-center'
          icon={<RiSparklingFill className='size-5' />}
          htmlType='submit'
          disabled={selected.length === 0}
          onClick={() => {
            getAnswer(true, selected.join(', '))
          }}
        >
          {t('submit')}
        </Button>
      </div>
    </Card>
  )
}

export default GeneratedCheckboxGroup
