import type { MenuProps } from 'antd'
import { Avatar, Button, Dropdown } from 'antd'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import useLoadingState from '@/hooks/context/useLoadingState'
import useAuth from '@/hooks/useAuth'

import { API_URL } from '@/constants/env'
import { DrawerContext } from '@/context/conversationDrawer/drawer.context'

const IndexDropdown: React.FC = () => {
  const { t } = useTranslation()
  const pathname = usePathname()
  const { startLoading } = useLoadingState()
  const { show } = useContext(DrawerContext)
  const { user } = useAuth()

  const items: MenuProps['items'] = []

  if (user?.role === 'ADMIN') {
    items.push({
      label: (
        <Link href='/admin/dashboard' className='px-3 py-1'>
          {t('admin-dashboard')}
        </Link>
      ),
      key: '0',
    })
  } else if (user?.role === 'USER') {
    items.push({
      label: (
        <Link href='/user/documents' className='px-3 py-1'>
          {t('user-dashboard')}
        </Link>
      ),
      key: '0',
    })
  }

  items.push(
    {
      type: 'divider',
      key: '1',
    },
    {
      label: (
        <>
          {user && (
            <Link href='' onClick={show} className='px-3'>
              {t('conversations')}
            </Link>
          )}
        </>
      ),
      key: '2',
    },
    {
      type: 'divider',
      key: '3',
    },
    {
      label: (
        <Link href={`${API_URL}/auth/logout`} className='px-3'>
          {t('logout')}
        </Link>
      ),
      key: '4',
    }
  )

  if (user) {
    return (
      <Dropdown menu={{ items }} placement='bottomRight'>
        <Avatar className='cursor-pointer bg-primary text-on-primary dark:bg-dark-primary dark:text-dark-on-primary'>
          <span className='text-lg'>
            {(user?.name ?? '').slice(0, 1).toUpperCase()}
          </span>
        </Avatar>
      </Dropdown>
    )
  } else {
    return (
      <Button type='text'>
        <Link
          href='/login'
          onClick={() => pathname !== '/login' && startLoading()}
          className='px-3'
          type='text'
        >
          {t('login-page-sign-in')}
        </Link>
      </Button>
    )
  }
}

export default IndexDropdown
