'use client'

import { Switch } from 'antd'
import { useTranslation } from 'react-i18next'

import useThemeState from '@/hooks/context/useThemeState'

import { Theme } from '@/context/theme/theme.types'
import { cn } from '@/utils/clsx'

interface ThemeSwitchProps {
  className?: string
}

const ThemeSwitch: React.FC<ThemeSwitchProps> = ({ className }) => {
  const { theme, switchTheme } = useThemeState()
  const { t } = useTranslation()

  return (
    <div className={cn('flex gap-2 items-center', className)}>
      <p>{t('dark-mode')}</p>
      <Switch checked={theme === Theme.DARK} onClick={switchTheme} />
    </div>
  )
}

export default ThemeSwitch
