import { RiSparklingFill } from '@remixicon/react'
import { Button, Card, Radio, Space } from 'antd'
import { t } from 'i18next'
import React, { useState } from 'react'

import { IGeneratedRadioGroup } from '@/types/chatbot'

interface GeneratedRadioGroupProps extends IGeneratedRadioGroup {
  getAnswer: (
    domain: boolean,
    question: string,
    agent?: string
  ) => Promise<void>
}

const GeneratedRadioGroup: React.FC<GeneratedRadioGroupProps> = ({
  items,
  getAnswer,
}) => {
  const [selected, setSelected] = useState<string>('')

  return (
    <Card>
      <Radio.Group
        onChange={(e) => {
          setSelected(e.target.value)
        }}
        value={selected}
      >
        <Space direction='vertical'>
          {items.map((item, index) => (
            <Radio key={index} value={item.text}>
              {item.text}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
      <div className='mt-4 flex justify-end'>
        <Button
          className='flex items-center'
          icon={<RiSparklingFill className='size-5' />}
          htmlType='submit'
          disabled={selected === ''}
          onClick={() => {
            getAnswer(true, selected)
          }}
        >
          {t('submit')}
        </Button>
      </div>
    </Card>
  )
}

export default GeneratedRadioGroup
