import { RiArrowRightDownLine } from '@remixicon/react'
import React from 'react'

import { AGENT_NAME } from '@/constants/env'

interface HeaderProps {
  setShowChatbot?: (show: boolean) => void
}

const Header: React.FC<HeaderProps> = ({ setShowChatbot }) => {
  return (
    <div className='flex h-12 items-center justify-between bg-surface p-4 text-on-surface shadow-md backdrop-blur-md sm:rounded-t dark:bg-dark-surface dark:text-dark-on-surface'>
      <div>{AGENT_NAME}</div>
      {setShowChatbot && (
        <RiArrowRightDownLine
          onClick={() => setShowChatbot(false)}
          className='flex cursor-pointer hover:text-on-surface sm:hidden hover:dark:text-dark-on-surface'
        />
      )}
    </div>
  )
}

export default Header
