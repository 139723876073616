import { useContext } from 'react'

import { TourContext } from '@/context/tour/tour.context'

const useTourState = () => {
  const context = useContext(TourContext)
  if (!Object.keys(TourContext).length) {
    throw new Error('TourContext must be used within its provider')
  }
  return context
}

export default useTourState
