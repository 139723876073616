import { Button } from 'antd'
import React from 'react'

import { IGeneratedButton } from '@/types/chatbot'

interface GeneratedButtonProps extends IGeneratedButton {
  getAnswer: (
    domain: boolean,
    question: string,
    agent?: string
  ) => Promise<void>
}

const GeneratedButton: React.FC<GeneratedButtonProps> = ({
  text,
  getAnswer,
}) => {
  return (
    <div className='text-right'>
      <Button
        onClick={() => {
          getAnswer(true, text)
        }}
      >
        {text}
      </Button>
    </div>
  )
}

export default GeneratedButton
