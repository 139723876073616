'use client'

import {
  RiBook2Line,
  RiCheckLine,
  RiFileCopyLine,
  RiGlobalLine,
  RiPlayCircleLine,
  RiThumbDownFill,
  RiThumbDownLine,
  RiThumbUpFill,
  RiThumbUpLine,
} from '@remixicon/react'
import { message, Tooltip } from 'antd'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useModalsState from '@/hooks/context/useModalsState'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'

import { IMessage } from '@/types/chatbot'

interface ActionButtonsProps {
  question: string
  questionInfo: IMessage
  getAnswer: (
    domain: boolean,
    question: string,
    agent?: string
  ) => Promise<void>
  onReferencesClick: (documents: string[], googleDriveUrls: string[]) => void
  updateQuestion: (question: IMessage) => void
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
  question,
  questionInfo,
  getAnswer,
  onReferencesClick,
  updateQuestion,
}) => {
  const {
    logId,
    domain,
    documents,
    googleDriveUrls,
    agent,
    message: msg,
    type,
    role,
    audio,
  } = questionInfo

  const { toggleModal } = useModalsState()
  const [textCopied, setTextCopied] = useState(false)
  const { t } = useTranslation()

  const askNonDomainQuestion = async () => {
    await getAnswer(false, question, agent)
  }

  const copyAnswer = (answer: string) => {
    return () => {
      // copy answer to user clipboard
      navigator.clipboard.writeText(answer)
      setTextCopied(true)

      setTimeout(() => {
        setTextCopied(false)
      }, 3000)
    }
  }

  const [feedback, setFeedback] = useState(questionInfo.feedbackScore)

  useEffect(() => {
    setFeedback(questionInfo.feedbackScore)
  }, [questionInfo.feedbackScore])

  const ThumbsUpIcon = feedback === 1 ? RiThumbUpFill : RiThumbUpLine
  const ThumbsDownIcon = feedback === -1 ? RiThumbDownFill : RiThumbDownLine

  const sendFeedback = async (feedback: number) => {
    const config = {
      method: 'post',
      withCredentials: true,
      ...configHeader,
      data: JSON.stringify({
        feedbackScore: feedback,
      }),
    }
    try {
      await axios(`${API_URL}/v2/chatbot/feedback-score/${logId}`, config)
      setFeedback(feedback)
    } catch (error) {
      console.error(error)
    }
  }

  const handleGenerateAudio = async () => {
    const config = {
      method: 'post',
      ...configHeader,
      withCredentials: true,
      data: JSON.stringify({
        text: msg,
      }),
    }
    message.loading(t('toast-audio-loading'), 0)
    try {
      const res = await axios(`${API_URL}/v2/chatbot/create-audio`, config)
      updateQuestion({
        ...questionInfo,
        audio: res.data.audio,
      })
      message.success(t('toast-audio-success'))
    } catch (error) {
      console.error(error)
      message.error(t('toast-audio-error'))
    }
  }

  return (
    <div className='absolute bottom-3 right-4 flex items-center gap-4 text-on-surface dark:text-dark-on-surface'>
      {!audio && (
        <Tooltip title={t('generate-audio')} mouseLeaveDelay={0}>
          <RiPlayCircleLine
            className='size-5 cursor-pointer hover:opacity-80'
            onClick={handleGenerateAudio}
          />
        </Tooltip>
      )}
      <Tooltip title={t('good-response')} mouseLeaveDelay={0}>
        <ThumbsUpIcon
          onClick={() => (feedback === 1 ? sendFeedback(0) : sendFeedback(1))}
          className='size-5 cursor-pointer hover:opacity-80'
        />
      </Tooltip>
      <Tooltip title={t('bad-response')} mouseLeaveDelay={0}>
        <ThumbsDownIcon
          onClick={() => (feedback === -1 ? sendFeedback(0) : sendFeedback(-1))}
          className='size-5 cursor-pointer hover:opacity-80'
        />
      </Tooltip>
      <Tooltip title={t('copy-answer')} mouseLeaveDelay={0}>
        {textCopied ? (
          <RiCheckLine
            className='size-5 cursor-pointer hover:opacity-80'
            onClick={copyAnswer(type === 'text' ? msg : '')}
          />
        ) : (
          <RiFileCopyLine
            className='size-5 cursor-pointer hover:opacity-80'
            onClick={copyAnswer(type === 'text' ? msg : '')}
          />
        )}
      </Tooltip>
      {domain && (
        <>
          <Tooltip title={t('show-references')} mouseLeaveDelay={0}>
            <RiBook2Line
              className='size-5 cursor-pointer hover:opacity-80'
              onClick={() => {
                onReferencesClick(documents, googleDriveUrls)
                toggleModal('references')
              }}
            />
          </Tooltip>
          {role === 'assistant' && (
            <Tooltip title={t('use-external-data')} mouseLeaveDelay={0}>
              <RiGlobalLine
                className='size-5 cursor-pointer hover:opacity-80'
                onClick={askNonDomainQuestion}
              />
            </Tooltip>
          )}
        </>
      )}
    </div>
  )
}

export default ActionButtons
