import { useContext } from 'react'

import { DrawerContext } from '@/context/conversationDrawer/drawer.context'

const useDrawerState = () => {
  const drawerContext = useContext(DrawerContext)
  if (!Object.keys(DrawerContext).length) {
    throw new Error('DrawerContext must be used within its provider')
  }
  return drawerContext
}

export default useDrawerState
