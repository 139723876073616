import { useContext } from 'react'

import { LoadingContext } from '@/context/loading/loading.context'

const useLoadingState = () => {
  const loadingContext = useContext(LoadingContext)
  if (!Object.keys(LoadingContext).length) {
    throw new Error('LoadingContext must be used within its provider')
  }
  return loadingContext
}

export default useLoadingState
