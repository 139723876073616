import Link from 'next/link'
import { usePathname } from 'next/navigation'
import React from 'react'
import { useTranslation } from 'react-i18next'

import useLoadingState from '@/hooks/context/useLoadingState'
import useAuth from '@/hooks/useAuth'

import ProfileBadge from '@/components/Admin/Profile/ProfileBadge'

const IndexNavigation = () => {
  const { t } = useTranslation()
  const pathname = usePathname()
  const { startLoading } = useLoadingState()
  const { user } = useAuth()

  return (
    <div className='flex items-center gap-4 text-sm sm:gap-8 sm:text-base'>
      {user ? (
        <>
          {user.role === 'ADMIN' ? (
            <Link
              href='/admin/dashboard'
              className='text-on-surface hover:text-on-surface/70 dark:text-dark-on-surface hover:dark:text-dark-on-surface/70'
            >
              {t('admin-dashboard')}
            </Link>
          ) : (
            <Link
              href='/user/documents'
              className='text-on-surface hover:text-on-surface/70 dark:text-dark-on-surface hover:dark:text-dark-on-surface/70'
            >
              {t('user-dashboard')}
            </Link>
          )}
          <ProfileBadge />
        </>
      ) : (
        <Link
          href='/login'
          onClick={() => pathname !== '/login' && startLoading()}
          className='text-on-surface hover:text-on-surface/70 dark:text-dark-on-surface hover:dark:text-dark-on-surface/70'
        >
          {t('login-page-sign-in')}
        </Link>
      )}
    </div>
  )
}

export default IndexNavigation
